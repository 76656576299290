.insurance-wrapper {
  .insurances {
    .insurance-item {
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      height: 56px;
      &:last-child {
        border-bottom: none;
      }
      .insurance-option {
        .insurance-icon {
          img {
            width: 24px;
            height: 24px;
          }
        }
        .insurance-name {
          color: $gray-800;
          font-size: 0.75rem;
          letter-spacing: 2px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    .insurance-value {
      color: $gray-800;
    }
    .insurance-button {

    }
  }
}
