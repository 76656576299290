.navbar {
  .container {
    border-bottom: $border-width solid $border-color;
    @media (max-width: map-get($grid-breakpoints, "sm")) {
      justify-content: center;
      border-bottom: none;
    }
  }
}

.navbar-brand-img-sm {
  max-width: 120px;
}

.navbar-brand-img-md {
  max-width: 140px;
}

.navbar-brand-img-lg {
  max-width: 160px;
}