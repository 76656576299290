@import '../common/variables';

.accordion {
  position: relative;

  .accordion-item {
    .accordion-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      h2 {
        font-family: $font-family-raleway;
        font-size: 32px;
        color: $gray-800;
        margin: 0;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        background-image: url('/images/common/arrow-dropdown.svg');
        background-size: 24px;
        width: 24px;
        height: 24px;
      }
    }

    .accordion-content {
      margin: 0.4em 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      @include transition($transition-base);

      h3 {
        text-align: left !important;
      }
    }

    &.active {
      .accordion-head {
        &:after {
          background-image: url('/images/common/arrow-dropup.svg');
        }
      }

      .accordion-content {
        margin: 1em 0;
        height: 100%;
        opacity: 1;
        overflow: inherit;
        @include transition($transition-base);
      }
    }
  }

  &.accordion--faqs {
    margin-top: 36px;
    .accordion-item {
      .accordion-head {
        padding: 20px;
        border: solid 1px $gray-300;
        &::after {
          right: 16px;
        }
        .accordion-title {
          font-family: $font-family-raleway;
          font-size: 18px;
          font-weight: 600;
          color: $gray-800;
          max-width: 90%;
        }
      }
      .accordion-content {
        font-family: $font-family-base;
        font-size: 18px;
        line-height: 1.56;
        color: $gray-500;
        margin: 0;
        p, ul{
          margin: 0;
        }
      }
      &.active{
        .accordion-title {
          color: $purple;
        }
        .accordion-content {
          padding: 20px 32px 30px;
          border-bottom: solid 1px $gray-300;
        }
      }
      &:not(:first-of-type){
        .accordion-head{
          border-top: none;
        }
      }
    }
  }
}
