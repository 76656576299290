.terms {
  text-align: justify;
  line-height: 1.4rem;
  ol {
    counter-reset: item;
    padding: 0.5rem 0 1.5rem 0.8rem;
  }

  ol > li {
    counter-increment: item;
  }

  ol ol > li {
    display: block;
    padding-bottom: 0.5rem;
  }

  ol ol > li:before {
    content: counters(item, ".") ". ";
    margin-left: -20px;
  }
}