.file-uploader-wrapper {
  max-width: 170px;

  .drop-zone-box {
    width: 100%;
    height: 96px;

    .file-uploader-box {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-color: $secondary-lighten-7;
      border: solid 2px $primary;
      position: relative;
      display: flex;
      align-items: center;
      text-align: center;
      min-width: 100px;

      .add-new-file {
        position: absolute;
        bottom: -2px;
        right: -2px;
      }

      .drop-zone-label {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        line-height: 1.6 !important;
      }

      &.is-invalid {
        border: solid 2px $danger;
      }
    }
  }
}

.file-thumbnail {
  width: 170px;
  height: 96px;
  border-radius: 12px;
  border: solid 2px $secondary-lighten-5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 1;
  img {
    border-radius: 12px;
    flex-shrink: 0;
    min-width: 50%;
    min-height: 50%;
    z-index: 2;
    width: 100%;
  }
  .thumbnail {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
  .check-file {
    position: absolute;
    bottom: -2px;
    right: -2px;
    z-index: 3;
  }
  .remove-file {
    position: absolute;
    bottom: -2px;
    right: -2px;
    z-index: 5;
    opacity: 0;
    .remove-file-button{
      padding: 0 !important;
    }

    .remove-file-button:focus{
      outline: none !important;
    }
  }
  .info-file {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 6;
    opacity: 0;
    .btn-link-image {
      height: 100%;
      width: 100%;
      text-decoration: none !important;
    }
    .remove-file-button{
      padding: 0 !important;
    }

    .remove-file-button:focus{
      outline: none !important;
    }
  }
  &:hover .check-file {
    display: none;
  }

  &:hover .remove-file {
    opacity: 1;
  }

  &:hover .info-file {
    opacity: 1;
    background: rgba(51, 51, 51, 0.5);
    width: 100%;
    height: 100%;
    text-align: left;
    padding: .2em .5em;
    z-index: 2;
    border-radius: 12px;
  }
}
