.text-raleway {
  font-family: $font-family-raleway;
}
.text-lato {
  font-family: $font-family-base;
}
.text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
small{
  font-size: 12px;
}
.text-xs {
  font-size: .65rem;
}
.border{
  .form-group{
    margin: 0;
  }
}
.header-bar {
  padding: 0 1rem;
  min-height: 45px;
  line-height: 45px;
  // margin-bottom: 1rem;
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $secondary-lighten-1;
  background-color: $secondary-lighten-8;

  .icon{
    img{
      max-width: 1.5rem;
    }
  }
  .amount{
    font-family: $font-family-base;
    font-size: 1rem;
    font-weight: normal;
    color: $gray-800;
    letter-spacing: 0;
  }
}

.min-h-0 {
  min-height: 0 !important;
}

.button-small-enabled {
  font-family: $font-family-raleway;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.3px;
  color: $primary;
}

.filename {
  font-family: $font-family-base;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $clean;
}
.lh-1 {
  line-height: 1;
}
.lh-md {
  line-height: $line-height-base * 1.5
}
.button-small-enabled {
  font-family: $font-family-raleway !important;
  font-size: 10px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: 1.3px !important;
  color: $primary !important;
}
.button-whatsapp {
  font-family: $font-family-raleway;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 2px;
  text-align: center;
  color: $clean;
  background-color: #25d366;
  border: 0;

  &.button-whatsapp-lg {
    border-radius: 90px;
  }
}

.quote-landing-title {
  font-family: $font-family-raleway;
  font-size: 48px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: $secondary-lighten-1;
  &.quote-landing-title-sm {
    font-size: 32px;
    line-height: 1.25;
  }
}

.quote-landing-icons-wrapper {
  background-color: #e6e6ed;
  border-radius: 50%;
  width: 50px;
  padding: 10px;
}

.side-cr-bar {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 2px;
  color: $clean;
}
.subheader-light {
  font-family:  $font-family-raleway;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 2px;
  text-align: center;
  color: $clean;
}
.regular-text-light {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $clean;
}
.title-step{
  font-size: $font-size-title-step;
  font-weight: normal;
  color: $font-color-title-step;

}
.services-light {
  font-family: $font-family-raleway;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 2px;
  text-align: center;
  color: $clean;
  margin-top: 1rem;
  text-transform: uppercase;
}

.text-initial {
  text-transform: initial !important;
}

.cursor-pointer {
  cursor: pointer;
}

.wrapper {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.border-attention {
  border-radius: 8px;
  background-color: $clean;
  border: solid 1px $secondary-lighten-5;

  &.border-status {
    border-radius: 4px;
  }
}
.icon-attention {
  max-width: 55px;
}
a, button{
  ::-moz-focus-inner {
    border: 0;
  }
}
.text-primary{
  color: $primary;
}
.bg-primary{
  background: $primary;
}
.text-primary-bbva{
  color: $primary-bbva;
}

.bg-opacity-danger {
    background-color: rgba(229, 105, 1, 0.05);
}

.link-details {
  font-family: $font-family-raleway;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: $primary;
  cursor: pointer;
  text-transform: uppercase;
}

.title-label {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  color: $secondary-lighten-4;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.detail-information {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-1;
}

.title-header {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $secondary-lighten-1;
}

.seminuevos-color {
  color: $turbo-cash-simulator;
}

.ul-wo-bullets {
  list-style-type:none;
}
