.form-group .form-control.range-input {
  font-size: 30px;
  border-bottom: none;
}

.form-group .form-control.disable-range-input {
  color: $gray-800;
}

.form-group .range-input:hover,
.form-group .range-input:focus,
.form-row .range-input:hover,
.form-row .range-input:focus {
  border-bottom: none !important;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  top: -2px;
  z-index: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background-color: $primary;
}

input[type="range"]:disabled::-webkit-slider-thumb {
    -webkit-appearance: none;
    display: none;
}

input[type="range"]:focus {
  outline:none;
}