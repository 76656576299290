.custom-select-search{
  position: relative;
  width: 100%;
  .dropdown-content{
    position: absolute;
    margin: 0;
    width: 100%;
    top: 1.5rem;
    z-index: 99;
    &.without-label {
      top: 0;
    }
    &.no-filter {
      top: inherit;
    }
    &.hide{
      display: none;
    }
    @media only screen and (max-width : map-get($grid-breakpoints, "sm")) {
      position: fixed;
      top:0;
      left: 0;
      z-index: 600;
      height: 100%;
      padding-top: 3rem;
      background: $background-select-search-component;
    }
  }

  .search-input{
    position: relative;
    min-height: 2.4rem;
    margin: 0;
    width: 100%;
    background: $background-select-search-component;
    z-index: 99;
    input{
      padding: 0 1em;
      border-bottom: 2px solid $primary;
      line-height: 2.4rem;
      min-height: 2.4rem;
    }
  }

  .over-item-bg{
    position: relative;
    height: 100%;
    max-height: 15rem;
    margin: 0;
    width: 100%;
    top: 0;
    background: $background-select-search-component;
    border-bottom-right-radius: $radius-select-search-component;
    border-bottom-left-radius: $radius-select-search-component;
    @include box-shadow($shadow-select-search-component);
    border: 1px solid $secondary-lighten-6;
    z-index: 99;
    overflow: hidden;
    @media only screen and (max-width : map-get($grid-breakpoints, "sm")) {
      max-height: 100%;
      border-radius: 0;
      z-index: 90;
      top: -1px;
      padding-top: 0;
      padding-bottom: 3rem;
    }
    .container-list-search{
      width: 100%;
      position: relative;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: inherit;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: $secondary-lighten-6;
      border-radius: 5px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 5px;
    }

  }

  .activate-button{
    border-width: 0 0 2px 0;
    padding: 0 1rem 0 0;
    border-style: solid;
    border-color: $secondary-lighten-5;
    line-height: 2.05rem;
    color: $secondary-lighten-2;
    outline: none;
    min-height: 2.05rem;
    width: 100%;
    font-size: 1rem;
    margin: 0;
    box-sizing: border-box;
    position: relative;

    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i{
      position: absolute;
      right: .7rem;
      top: .5rem;
      z-index: -1;
      pointer-events: none;
    }
    &.disable, &.placeholder{
      color: $placeholder;
      text-decoration: none;
    }
    &.is-invalid {
      border-bottom: 2px solid $danger;
    }
    &:hover{
      text-decoration: none;
      border-color: $primary;
    }
    &.cell {
      border-width: 0px
    }
  }

  .result-list{
    margin: 0;
    padding: 0;
    li{
      display: block;
      width: 100%;
      list-style: none;
      &:hover{
        outline: none;
        background: $secondary-lighten-7;
        font-weight: bold;
      }
      a{
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        font-size: 1rem;
        color: $secondary-lighten-2;
        border-bottom: 1px solid $secondary-lighten-6;
        text-decoration: none;
        display: flex;
        align-items: center;
        &:focus{
          outline: none;
          background: $secondary-lighten-7;
        }
      }
      &:last-child{
        a{
          border-bottom: 0;
        }
      }
    }
    ol {
      padding: 0;
      display: block;
      width: 100%;
      list-style: none;
      a{
        text-align: center;
        display: block;
        padding: .5rem 1rem;
        font-size: .8rem;
        color: $secondary-lighten-2;
        text-decoration: none;
      }
    }
  }
  .disable-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .close-responsive-data{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 3rem;
    background: $secondary-lighten-7;
    padding-right: .75rem;
    padding-top: .5rem;
    display: none;
    @media only screen and (max-width : map-get($grid-breakpoints, "sm")) {
      display: block;
      z-index: 10;
    }
    label {
      width: 100%;
      display: block;
      text-align: center;
    }
    .close-button {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
    }
  }
}

.stateIndicator {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  &.active {
    background-color: $green;
  }
  &.inactive {
    background-color: $secondary-lighten-5;
  }
}
