.dropdown-menu {
  border: none;
  box-shadow: 0 4px 16px 0 rgba(147, 147, 156, 0.6);
  .dropdown-item {
    cursor: pointer;

    @include hover-focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg);
    }

    &.active,
    &:active {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg);
    }
  }
}