$secondary: gray('900');
$secondary-lighten-1: gray('800');
$secondary-lighten-2: gray('700');
$secondary-lighten-3: gray('600');
$secondary-lighten-4: gray('500');
$secondary-lighten-5: gray('400');
$secondary-lighten-6: gray('300');
$secondary-lighten-7: gray('200');
$secondary-lighten-8: gray('100');

$secondary-colors: ();
$secondary-colors: map-merge((
        'secondary': $secondary,
        'secondary-lighten-1': $secondary-lighten-1,
        'secondary-lighten-2': $secondary-lighten-2,
        'secondary-lighten-3': $secondary-lighten-3,
        'secondary-lighten-4': $secondary-lighten-4,
        'secondary-lighten-5': $secondary-lighten-5,
        'secondary-lighten-6': $secondary-lighten-6,
        'secondary-lighten-7': $secondary-lighten-7,
        'secondary-lighten-8': $secondary-lighten-8
), $secondary-colors);

@each $color, $value in $secondary-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $secondary-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

$zindex-modal-backdrop-latam:             1080 !default;
$zindex-tooltip-latam:                    1090 !default;

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.max-h-60-screen {
  max-height: 60vh;
}
