.form-group, .form-row {
  min-height: 80px;
  position: relative;
  .asterisk{
    color: $purple;
    font-weight: bold;
    font-family: $font-family-base;
    line-height: 16px;
    letter-spacing: 2px;
  }
  .form-control {
    font-size: 16px;
    border-bottom: 2px solid $secondary-lighten-5;
    padding-bottom: 2px;
    box-shadow:none;
    background: transparent;
    @include hover-focus {
      border-bottom: 2px solid $purple;

      &.is-invalid {
        box-shadow: none;
      }
    }
    &.is-invalid {
      border-bottom: 2px solid $danger;
    }
  }

  .form-control-simple {
    border-bottom: 0px !important;
  }

  .marketing {
    text-transform: initial !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: $secondary-lighten-1 !important;
  }

  select{
    position: relative;
    z-index: 0;
    background: transparent;
    &:focus{
      background: transparent;
    }
    &.form-control:not([size]):not([multiple]) {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }
  span{
    &.arrow{
      position: absolute;
      right: 10px;
    }
  }
  &.datepicker{
    span{
      &.arrow{
        z-index: 0;
        position: absolute;
        right: 1em;
        top: 5px;
      }
    }
  }
  .input-group-prepend{
    margin-right: 0.5em;
    img{
      max-width: 24px;
    }
  }
}

.table-form-group {
  min-height: 42px;
  position: relative;
  .form-control {
    font-size: 16px;
    border-bottom: 0;
    padding-bottom: 2px;
    box-shadow:none;
    background: transparent;
    @include hover-focus {
      border-bottom: 2px solid $purple;

      &.is-invalid {
        box-shadow: none;
      }
    }
    &.is-invalid {
      border-bottom: 2px solid $danger;
    }
  }
}

.input-modal {
  display: initial;
}

.resend-text-modal {
  font-size: 12px;
  letter-spacing: 2px;
}

.form-text {
  font-family: $font-family-raleway;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 2px;
  text-align: right;
  text-transform: uppercase;
  color: $secondary-lighten-4;

  &.is-invalid {
    color: $danger;
  }
}

.custom-input{
  > label, > div > div > label {
    font-family: $font-family-raleway;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 2px;
    color: $secondary-lighten-4;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    min-height: 16px;
    .ic {
      position: absolute;
      right: 0;
    }
  }
  & input:-webkit-autofill {
    box-shadow:0 0 0 50px white inset;
    -webkit-box-shadow:0 0 0 50px white inset;
  }
  sup {
    font-size: 11px;
    font-weight: bold;
  }
}

.radio-button{

  .label-radio{
    font-family: $font-family-raleway;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 2px;
    color: $secondary-lighten-4;
    text-transform: uppercase;
  }

  .custom-radio {
    /*padding-bottom: 8px;*/

    label {
      font-size: 16px;
      font-weight: normal;
      line-height: (1rem * $line-height-base);
      color: $secondary-lighten-3;
      @media (max-width: map-get($grid-breakpoints, "sm")) {
        font-size: 14px;
        line-height: 1.25;
      }
    }

    .custom-control-label {
      padding-left: 10px;
      display: block;
    }

    .custom-control-label::before {
      transform: scale(1.4);
      border: 1px solid $primary;
      background-color: $white;
    }

    .custom-control-label::after {
      transform: scale(1.4);
    }

    .custom-control-input:checked ~ .custom-control-label::after{
      background-image: none;
    }

    .custom-control-input.checked ~ .custom-control-label {
      &::before {
        @include gradient-bg($custom-control-indicator-checked-bg);
      }
      &::after {
        background-image: $custom-radio-indicator-icon-checked;
      }
    }

  }
}

.checkbox{

  label {
    font-size: 16px;
    font-weight: normal;
    line-height: (1rem * $line-height-base);
    color: $secondary-lighten-3;
    @media (max-width: map-get($grid-breakpoints, "sm")) {
      font-size: 14px;
      line-height: 1.25;
    }
  }

  .custom-checkbox {
    /*padding-bottom: 8px;*/

    .custom-control-label {
      padding-left: 10px;
      display: block;
    }

    .custom-control-label::before {
      transform: scale(1.4);
      border: 1px solid $primary;
      background-color: $white;
    }

    .custom-control-label::after {
      transform: scale(1.4);
    }

    .custom-control-input:checked ~ .custom-control-label::before{
      background: $primary;
    }
    .custom-control-input:checked ~ .custom-control-label::after{
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTMgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQzLjEgKDM5MDEyKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiIHBvaW50cz0iMSA1Ljc4NSA0LjMxOCA5LjkzNCAxMS40NjQgMSI+PC9wb2x5bGluZT4KICAgIDwvZz4KPC9zdmc+');
      background-size: 55% 50%;
    }
    sup {
      font-size: 11px;
      font-weight: bold;
    }
  }
  .label-error{
    padding-left: 38px;
  }
}

.list-group {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  &.horizontal{
    flex-direction: row;
  }
  &.column{
    flex-direction: column;
  }
  &.column-reverse{
    flex-direction: column-reverse;
  }

  &.list-group-borderless {
    li {
      padding: $input-btn-padding-y-sm $grid-gutter-width 0 $input-btn-padding-y-sm;
      border: 0 none;
    }
  }
  &.list-group-flush {
    li {
      padding: $list-group-item-padding-y 0;
    }
  }
}

.btn {
  font-family: $font-family-raleway;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-stretch: normal;
  letter-spacing: 2px;
  border: solid 2px $primary;
  height: 48px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    font-size: 12px;
  }
  &.rounded {
    border-radius: 50px !important;
  }
  &.btn-sm {
    height: 24px;
    font-size: 10px;
    border-width: 1px;
  }
  &.small {
    height: inherit;
    padding: $input-btn-padding-y-sm  $input-btn-padding-x-sm;
  }
  &.circle-small {
    height: inherit;
    padding: $input-btn-padding-y-sm  $input-btn-padding-y-sm;
  }
  &.btn-info{
    &:focus, &:not(:disabled):not(.disabled):active:focus{
      box-shadow: none;
    }
  }
  &.btn-outline-info {
    background-color: white;
    &:hover {
      color: $primary;
    }
  }
  @media (min-width: map-get($grid-breakpoints, "md")) {
    &.btn-block{
      width: initial;
    }
  }
  @media (max-width: map-get($grid-breakpoints, "md")) {
    &.resume-button{
      position: fixed;
      bottom: 1rem;
      z-index: 1;
      left: 0;
      margin-left: 3%;
      margin-right: 3%;
      width: 94%;
    }
  }
}


.link-no-padding {
  padding: 0px !important;
}

.table-form-group {

  .form-control {
    font-size: 16px;
    box-shadow:none;
    background: transparent;
    padding: 0px;
    color: $secondary-lighten-2;
  }

  select{
    position: relative;
    z-index: 0;
    background: transparent;
    &:focus{
      background: transparent;
    }
    &.form-control:not([size]):not([multiple]) {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  input[type="text"]{
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $secondary-lighten-6;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $secondary-lighten-6;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $secondary-lighten-6;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $secondary-lighten-6;
    }
  }

  span{
    &.arrow{
      position: absolute;
      right: 0;
    }
  }
}
button {
  &:active, &:focus {
    outline: none;
    border: none;
  }
}

.svg-invalid {
  stroke: $danger;
}
