.login {
  height: 100vh;
  .login-image{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .login-form-wrapper{
    .login-form{
      height: 93%;
    }
  }
}

.logout {
  position: absolute;
  right: 2em;
  top: 2em;
}
