.regular-text {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-3;
}

.disclaimer-text {
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $secondary-lighten-3;
}

.link-text-center {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  color: $primary;
}

.input-text-enabled {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-1;
}

.input-text-disabled {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-4;
}

.menu-regular {
  font-family: $font-family-raleway;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: $secondary-lighten-4;
}

.header-list-item {
  font-family: $font-family-raleway;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $secondary-lighten-1;
}

.text-description {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $secondary-lighten-3;
}

.text-title {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $secondary-lighten-1;
}

.status-label {
  font-family: $font-family-raleway;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $secondary-lighten-4;
}

.time-ago {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: $secondary-lighten-4;
}

.validation-error {
  font-family: $font-family-raleway;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 2px;
  color: $warning;
  text-transform: uppercase;
}

.regular-text {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-3;
}

.list-menu-left-select {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-1;
}

.title-status {
  font-family: $font-family-raleway;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $secondary-lighten-4;
}

.information-text {
  font-family: $font-family-raleway;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 1px;
  color: $white;
}

.header-section {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  color: $secondary-lighten-1;
}

.main-title {
  font-family: $font-family-raleway;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 32px;
  letter-spacing: normal;
  color: $secondary-lighten-1;
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    font-size: 24px;
    line-height: 1.33;
  }
}

.main-title {
  font-family: $font-family-raleway;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 32px;
  letter-spacing: normal;
  color: $secondary-lighten-1;
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    font-size: 24px;
    line-height: 1.33;
  }
}

.label {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  color: $secondary-lighten-4;
}

.error-title {
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  color: $secondary-lighten-1;
}

.range-label {
  font-family: $font-family-base;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.4;
  letter-spacing: normal;
  color: $gray-900;
  padding: 12px 0;
}
