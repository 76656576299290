.table {
    .col-label {
        width: 30%
    }
    .col-value {
        width: 35%
    }
    tr {
        margin-bottom: 1rem;
        .header {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 2px;
            font-family: $font-family-raleway;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
        }
        .item-link {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: $purple;
        }
        .item {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
        }
    }
    &.clickable {
        tr {
            &:hover {
                cursor: pointer;
            }
        }
    }
    &.table-fixed-layout {
        table-layout: fixed;
        th, td {
            padding: $table-cell-padding $table-cell-padding-sm;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}