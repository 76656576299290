@import '../common/commonFileUploader';
@import '../common/form';
@import '../common/loader';
@import 'insurances';
@import '../common/customSelectSearch';
@import 'landing';

html, body {
  height: 100%
}

@include media-breakpoint-down(sm) {
  .btn-order-first {
    order: -1;
  }
  .border-xs-0 {
    border: hidden !important;
  }
}

.margin-offers {
  margin-bottom: -10px;
}

#wizard {
  height: 100%;
  .wrapper {
    min-height: 100vh;
    margin: 0 auto;
    .wrapper-content {
      min-height: 100%;
    }
    .wrapper-sidebar {
      position: relative;
      .mobile-menu {
        position: absolute;
        top: -70px;
        right: 10px;
        width: 50px;
        height: 35px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 2;
        .budge-mobile-menu {
          position: absolute;
          width: 6px;
          height: 6px;
          background: $budge;
          border-radius: 6px;
          top: 8px;
          right: 13px;
        }
      }
      .wrapper-content-mobile-menu {
        .content-mobile-menu{
          z-index: 1;
          position: absolute;
          background: #ffffff;
          right: 10px;
          height: 130px;
          width: 50px;
          top: -70px;
          box-shadow: 0 5px 24px rgba(0, 0, 0, 0.11);
          border-radius: 5px;
          .wrapper-logout {
            position: absolute;
            right: 10px;
            top: 90px;
          }
        }
        .fade-enter {
          opacity: 0.5;
          max-height: 0;
        }

        .fade-enter-active {
          opacity: 1;
          max-height: 100vh;
          transition: all 500ms;
        }

        .fade-exit {
          opacity: 1;
          max-height: 100vh;
        }

        .fade-exit-active {
          opacity: 0;
          max-height: 0;
          padding: 0;
          margin: 0 auto;
          transition: all 1000ms, max-height 500ms;
        }
      }
      .wrapper-assistant {
        border-top: 1px solid $gray-300;
        padding-top: 1em;
        padding-bottom: 1em;
        .content-assistant {
          h4 {
            font-family: $font-family-raleway;
            font-size: 21px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $gray-900;
          }
          p {
            font-family: $font-family-base;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $gray-600;
            margin: 0;
          }
        }
        .assistant {
          .img-assistant {
            width: 60px;
            height: 60px;
            position: relative;
            margin-right: 0.5em;
            & > img {
              border-radius: 50%;
              border: 1px solid $gray-400;
            }
            .budge-assistant {
              position: absolute;
              right: 0;
              top: -10px;
            }
          }
          .details-assistant {
            h5 {
              font-family: $font-family-raleway;
              font-size: 16px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $gray-700;
              margin: 0;
            }
            p {
              font-family: $font-family-base;
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $gray-700;
              margin: 0;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .wrapper-assistant {
          border-top: none;
          padding-top: 0;
          padding-bottom: 0;
          position: absolute;
          top: 44px;
          right: 1px;
          .assistant {
            .img-assistant {
              width: 33px;
              height: 33px;
              .budge-assistant {
                width: 16px;
                height: 16px;
                position: absolute;
                right: -7px;
              }
            }
          }
        }
      }
    }
    .wrapper-wizard {
      position: relative;
      background: $wrapper-wizard-content;
      padding: 50px 35px;
      .brand-wizard {
        position: absolute;
        bottom: 0.3em;
        right: 1em;
      }
      .container {
        position: relative;
        .logo-inside-wizard {
          position: absolute;
          top: -90px;
          left: 0;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .wrapper-wizard {
        background: white;
      }
    }
    &.error-not-eligible {
      .wrapper-wizard {
        padding: 100px 35px;
      }
    }
    @include media-breakpoint-down(sm) {
      &.error-not-eligible{
        .wrapper-content {
          min-height: 100%;
        }
      }
    }
    &.login-page {
      .wrapper-wizard{
        padding: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      &.login-page {
        padding: 0 1em;
        .wrapper-sidebar {
          display: none;
        }
      }
    }
    &.wrapper-landing {
      .wrapper-wizard {
        padding: 0 15px;
      }
    }
    @include media-breakpoint-down(sm) {
      .wrapper-wizard {
        padding: 0 15px;
      }
    }
    @include media-breakpoint-down(sm) {
      .wrapper-content {
        min-height: 0;
      }
    }
    .step-name{
      font-family: $font-family-raleway;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
      color: $gray-800;
      width: calc(100% - 20px - 41px);;
      height: 44px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .modal-info {
    .wrapper-assistant {
      .content-assistant {
        h4 {
          font-family: $font-family-raleway;
          font-size: 21px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $gray-900;
        }
        p {
          font-family: $font-family-base;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $gray-600;
          margin: 0;
        }
      }
      .assistant {
        .img-assistant {
          width: 60px;
          height: 60px;
          position: relative;
          margin-right: 0.5em;
          & > img {
            border-radius: 50%;
            border: 1px solid $gray-400;
          }
          .budge-assistant {
            position: absolute;
            right: 0;
            top: -10px;
          }
        }
      }
      .details-assistant {
        h5 {
          font-family: $font-family-raleway;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $gray-700;
          margin: 0;
        }
        p {
          font-family: $font-family-base;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $gray-700;
          margin: 0;
        }
      }
    }
  }
}
