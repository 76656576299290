.wrapper-landing {
  background: #F7F8FC;
  .wrapper-header-landing {
    background: linear-gradient(to bottom, $purple-200, $purple-300);
    min-height: 371px;
    .image-header {
      background: url('/images/common/image-dot-mexico.svg') no-repeat center center;
    }
    @include media-breakpoint-down(md) {
      .logo-brand {
        text-align: center;
      }
    }
  }
  .wrapper-header-landing-without-header {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .wrapper-header-landing-turbo-cash {
    background: linear-gradient(to bottom, $purple-200, $purple-300);
    min-height: 371px;
    @include media-breakpoint-down(sm) {
      min-height: 210px;
    }
    .image-header {
      background: url('/images/common/mc-logo-light.svg') no-repeat;
      background-size: 800px;
      background-position-y: 50px;
      background-position-x: calc(100% + 270px);
      opacity: 0.07;
      @include media-breakpoint-down(md) {
        background-size: 550px;
        background-position-y: 100px;
        background-position-x: calc(100% + 280px);
      }
      @include media-breakpoint-down(sm) {
        background-image: none;
      }
    }
    @include media-breakpoint-down(md) {
      .logo-brand {
        text-align: center;
      }
    }
  }
  .wizard-landing {
    border-radius: 6px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    margin-top: -65px;
    &.landing-turbo-cash {
      & > h3 {
        font-size: 32px;
        top: -135px;
        @include media-breakpoint-down(sm) {
          top: -130px;
          font-size: 18px;
        }
      }
      & > .sub-title {
        font-size: 22px;
        top: -70px;
        @include media-breakpoint-down(sm) {
          top: -120px;
          font-size: 16px;
        }
      }
    }
    & > .text-area-content {
      position: absolute;
      top: -200px;
      right: 0;
      left: 0;
      display: flex;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
      @include media-breakpoint-down(sm) {
        top: -290px;
        img {
          max-width: 80px;
        }
      }
      h4 {
        color: white !important;
        font-weight: bold;
        margin: 0;
        font-size: 20px;
        @include media-breakpoint-down(md) {
          text-align: left;
        }
        @include media-breakpoint-down(sm) {
          text-align: center;
          font-size: 22px;
        }
      }
      h5 {
        color: white !important;
        margin: 0;
        font-size: 20px;
        span {
          text-decoration: underline;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
        }
        @include media-breakpoint-down(sm) {
          margin-top: 1em;
          text-align: center;
          font-size: 16px;
        }
      }
      ul {
        margin: 1em 0;
        padding-left: 20px;
        @include media-breakpoint-down(sm) {
          margin-top: 2em;
          margin-bottom: 2em;
        }
        li {
          font-weight: lighter;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: white;
          font-size: 16px;
          @include media-breakpoint-down(md) {
            text-align: left;
          }
          @include media-breakpoint-down(sm) {
            text-align: left;
            font-size: 14px;
          }
        }
      }
    }
    & > h3 {
      position: absolute;
      top: -240px;
      right: 0;
      left: 0;
      text-align: center;
      color: white !important;
      font-size: 31px;
    }
    & > .sub-title {
      font-family: $font-family-raleway;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: white !important;
      position: absolute;
      right: 0;
      left: 0;
      top: -180px;
    }
    & > .sub-sub-title {
      font-family: $font-family-raleway;
      font-size: 28px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: white;
      position: absolute;
      right: 0;
      left: 0;
      top: -120px;
    }

    @include media-breakpoint-down(sm) {
      .sub-title, .sub-sub-title {
        margin-top: 2.5rem;
      }
    }
    .side-form {
      padding: 2em;
      h3 {
        font-size: 24px;
      }
      .section-details {
        .section-details-item {
          padding: 1em;
          &.with-insurance {
            @include media-breakpoint-down(sm){
              border-bottom: 1px solid $gray-300 !important;
            }
            @include media-breakpoint-up(md){
              border-right: 1px solid $gray-300 !important;
            }
          }
        }
        .title {
          font-family: $font-family-base;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: $gray-800;
        }
        .description {
          font-family: $font-family-base;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $gray-600;
        }
        .value {
          font-family: $font-family-base;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $gray-500;
        }
      }
    }
    .side-calculator {
      background: $gray-200;
      border-top-right-radius: 6px;
      .monthly-quotes {
        background: $principal-simulator;
        padding: 1em 0.5em;
        border-top-right-radius: 6px;
        min-height: 200px;
        .options-monthly {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .monthly {
            border-radius: 2px;
            border: solid 1px white;
            color: white;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            margin-bottom: 0.3em;
            cursor: pointer;
            &.checked {
              background: white;
              color: $principal-simulator;
            }
          }
          .label {
            text-transform: uppercase;
            font-family: $font-family-base;
            font-size: 9px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            text-align: center;
            color: white;
            letter-spacing: 1px;
          }
        }
        .title-quotes {
          text-transform: uppercase;
          font-family: $font-family-raleway;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 2px;
          text-align: center;
          color: white;
        }
        .sub-title-quotes {
          font-family: $font-family-base;
          font-size: 11px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 1px;
          text-align: center;
          color: white;
          min-height: 80px;
        }
        .value-quotes {
          font-family: $font-family-base;
          font-size: 42px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: white;
          min-height: 80px;
        }
        .value-quotes-small {
          font-size: 16px;
          min-height: 0px;
          margin-bottom: 15px;
        }
      }
      @include media-breakpoint-down(md) {
        .monthly-quotes {
          border-top-left-radius: 6px;
        }
      }
      .financing-details {
        .section-details {
          .title {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: $gray-800;
          }
          .description {
            font-family: $font-family-base;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $gray-600;
          }
          .value {
            font-family: $font-family-base;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: right;
            color: $gray-500;
          }
        }
      }
    }
    .side-calculator-profile {
      background: $gray-200;
      border-top-right-radius: 6px;
      .monthly-quotes {
        padding: 2em 0.5em;
        border-top-right-radius: 6px;
        .options-monthly {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .monthly {
            border-radius: 2px;
            border: solid 1px $primary;
            color: $gray-600;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            margin-bottom: 0.3em;
            cursor: pointer;
            background: $gray-300;
            &.checked {
              background: $primary;
              color: white;
            }
          }
          .label {
            text-transform: uppercase;
            font-family: $font-family-base;
            font-size: 9px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            text-align: center;
            color: $gray-700;
            letter-spacing: 1px;
          }
        }
        .title-quotes {
          text-transform: uppercase;
          font-family: $font-family-raleway;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 2px;
          text-align: center;
          color: white;
        }
        .sub-title-quotes {
          font-family: $font-family-base;
          font-size: 11px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 1px;
          text-align: center;
          color: white;
          min-height: 80px;
        }
        .value-quotes {
          font-family: $font-family-base;
          font-size: 42px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: white;
          min-height: 80px;
        }
      }
      @include media-breakpoint-down(md) {
        .monthly-quotes {
          border-top-left-radius: 6px;
        }
      }
      .financing-details {
        .title-quotes {
          text-transform: uppercase;
          font-family: $font-family-raleway;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 2px;
          text-align: center;
          color: $gray-800;
        }
        .sub-title-quotes {
          font-family: $font-family-base;
          font-size: 11px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 1px;
          text-align: center;
          color: $gray-800;
          min-height: 60px;
        }
        .value-quotes {
          font-family: $font-family-base;
          font-size: 42px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $gray-800;
          min-height: 60px;
        }
        .small-text {
          font-size: 0.7em;
          &.checked {
            color: $primary;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wizard-landing {
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      margin-top: 0;
    }

    .wizard-turbo-footer, .turbo-wizard-info {
      display: none;
    }
  }

  .motorfy-logo-turbo {
    margin-top: 10px;
  }
  .wizard-turbo-title {
    text-align: center;
    h3 {
      text-align: center;
      color: $gray-800 !important;
    }

    .sub-title {
      font-family: $font-family-base;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $gray-600;
    }
  }

  .footer-text {
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: $gray-600;
  }

  .wizard-turbo {
    @extend .wizard-landing;
    border-radius: 6px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    margin-top: 26px;
    .side-form {
      padding: 2em;
    }
    @include media-breakpoint-down(sm) {
      .side-form {
        padding: 0;
      }
    }
    .side-calculator {
      background: $gray-200;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      .monthly-quotes {
        background: $primary;
        padding: 1em 0.5em;
        border-top-right-radius: 4px;
        .options-monthly {
          .monthly {
            &.checked {
              background: white;
              color: $primary;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .monthly-quotes {
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .wizard-turbo {
      border: none;
      box-shadow: none;
    }

    .order-btn-simulator {
      order: -1;
    }
  }

  h3 {
    font-family: $font-family-raleway;
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0;
    color: $gray-900;
  }
  @include media-breakpoint-down(sm) {
    h3 {
      text-align: center;
    }
  }
  .rectangle {
    width: 42px;
    height: 8px;
    background-color: $purple;
    display: block;
  }
  @include media-breakpoint-down(sm) {
    .rectangle {
      margin: 1em auto;
    }
  }
  .description-info {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $gray-600;
  }
  @include media-breakpoint-down(sm) {
    .description-info {
      text-align: center;
    }
  }
  .landing-check {
    box-shadow: 5px 10px 30px 0 rgba(41, 26, 204, 0.12);
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    min-height: 80px;
    .check {
      background: $principal-simulator;
    }
    .description {
      font-family: $font-family-base;
      font-size: 18px;
      padding: 0.5em;
      display: flex;
      align-items: center;
    }
  }
  .landing-feature {
    border-radius: 6px;
    box-shadow: 5px 10px 30px 0 rgba(41, 26, 204, 0.12);
    background-color: white;
    overflow: hidden;
    padding: 2em;
    margin: 0.5em;
    min-height: 330px;
    .icon {
      min-width: 65px;
      min-height: 65px;
      width: 67px;
      height: 67px;
      background-color: $primary;
      border-radius: 50%;
    }
    .title {
      font-family: $font-family-raleway;
      font-size: 22px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: -0.8px;
      color: #4a4a4a;
    }
    .description {
      font-family: $font-family-base;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #9b9b9b;
    }
  }
  .landing-features-option {
    & > div{
      &:first-child {
        border-right: 1px solid white;
      }
      @include media-breakpoint-down(sm) {
        &:first-child {
          border-right: none;
        }
      }
    }
  }
  .landing-feature-option {
    border-radius: 6px;
    overflow: hidden;
    margin: 0.5em;
    .icon {
      min-width: 65px;
      min-height: 65px;
      width: 67px;
      height: 67px;
      background-color: white;
      border-radius: 50%;
      margin-right: 0.5em;
    }
    .title {
      font-family: $font-family-raleway;
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.61;
      letter-spacing: -0.7px;
      color: white;
      strong {
        font-weight: bold;
      }
    }
  }
  .wrapper-landing__monthly-rate{
    font-size: 16px;
    color: #8b8b8b;
  }
}
