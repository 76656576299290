.float-button-container{
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  z-index: 1;
  .button-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:hover {
      .float-button-tooltip {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .float-button-tooltip {
      color: white;
      text-align: center;
      width: 176px;
      height: 40px;
      background-color: $secondary-lighten-1;
      box-shadow: 0 4px 8px 0 $secondary-lighten-4;
      border-radius: 4px;
      display: none;
      margin-right: 10px;

      &::after{
        content: "";
        left: 100%;
        top: 50%;
        border-left: 8px solid $secondary-lighten-1;
        border-bottom: 8px solid transparent;
        border-top: 8px solid transparent;
        margin-top: -8px;
      }
      span{
        font-family: $font-family-raleway;
        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        text-align: center;
        color: $secondary-lighten-8;
      }
    }
    .float-button {
      cursor: pointer;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      text-align: center;
      line-height: 60px;
      background: $primary;
      @include transition($transition-cubic-out-slow);
      &.disable{
        background: $secondary-lighten-4;
      }
    }
  }
}
