@mixin box-shadow($args1) {
  -webkit-box-shadow: $args1;
  -moz-box-shadow: $args1;
  box-shadow: $args1;
}

@mixin transparent-background-color($color) {
  @for $i from 1 through 100 {
    &-#{$i} {
      background-color: rgba($color, $i * 0.01) !important;
    }
  }
}