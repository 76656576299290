.list-group-item{
  background: transparent;
  &.disabled, &:disabled{
    background: transparent;
  }
}

.steps{
  .list-group {
    &.steps-list {
      li {
        &.list-group-item {
          border: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 2px;
          font-family: $font-family-raleway;
          padding: .75rem 0 .75rem 2rem;
          position: relative;
          &.disabled {
            color: $gray-500;
          }
          &.checked {
            color: $primary;
            cursor: pointer;
          }
          &.current-checked {
            color: $primary;
            cursor: pointer;
          }
          .bullet-check {
            position: absolute;
            left: 10px;
            top: auto;
            bottom: auto;
          }
        }
      }
    }
  }
  .steps-item{
    font-family: $font-family-raleway;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    .step-name{
      color: $gray-800;
      width: calc(100% - 48px);
      height: 44px;
      text-align: center;
      text-transform: uppercase;
    }
    .step-back{
      min-width: 24px;
    }
  }
}
