.modal-calculator-container {
  position: absolute;
  width: 100%;
  padding-left: 130px;
  height: 318px;
  animation: slide-down .5s ease-out;
  animation-fill-mode: forwards;
  display: none;
  &.open {
    animation: slide-up .5s ease-out;
    bottom: 0;
    display: block;
  }
  .modal-calculator {
    height: 318px;
    box-shadow: 10px 4px 16px 0 rgba($secondary-lighten-4, 0.6);
    background-color: $clean;
    border-top: solid 1px $secondary-lighten-6;
    position: relative;
    width: 100%;
    z-index: 100;
    padding-top: 15px;
    .load-data-calculator{
      position: absolute;
      width: 100%;
      height: 90%;
      z-index: 140;
      display: flex;
      align-items: center;
      justify-content: center;
      .loader-backdrop {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -10;
        background-color: $clean;
        opacity: $modal-backdrop-opacity;
      }
    }

    &::before {
      content: '';
      width: 80px;
      height: 50px;
      position: absolute;
      right: 8px;
      top: 0;
      z-index: 0;
      background-color: $clean;
    }
    &::after {
      content: '';
      width: 64px;
      height: 64px;
      position: absolute;
      right: 16px;
      top: -32px;
      z-index: -10;
      border-radius: 50%;
      box-shadow: 0 4px 16px 0 rgba($secondary-lighten-4, 0.6);
      background-color: #fff;
    }
    .close-modal-calculator {
      cursor: pointer;
      position: absolute;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      right: 16px;
      top: -32px;
      text-align: center;
      line-height: 60px;
      &:hover {
        background: $secondary-lighten-7;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    display: none;
    bottom: -350px;
  }
  100% {
    display: block;
    bottom: 0;
  }
}

@keyframes slide-down {
  0% {
    display: block;
    bottom: 0;
  }
  99% {
    display: block;
    bottom: -350px;
  }
  100% {
    display: none;
    bottom: -350px;
  }
}