.tooltip-div-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    .float-div-tooltip {
      display: flex;
    }
  }
  .float-div-tooltip {
    color: white;
    text-align: center;
    width: 100px;
    height: 40px;
    background-color: $secondary-lighten-1;
    box-shadow: 0 4px 8px 0 $secondary-lighten-4;
    border-radius: 4px;
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    z-index: 1000;
    position: absolute;
    top: 30px;
    right: -15px;

    &::after{
      content: "";
      left: 100%;
      top: 50%;
      border-left: 8px solid $secondary-lighten-1;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      margin-top: -8px;
    }
    span{
      font-family: $font-family-raleway;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      text-align: center;
      color: $secondary-lighten-8;
    }
  }
}
