@use "sass:math";

.alerts {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-tooltip-latam;
  width: 100%;
  height: 0;
  padding: 0 0.5rem;

  .fade-enter {
    opacity: 0.5;
    max-height: 0;
  }

  .fade-enter-active {
    opacity: 1;
    max-height: 100vh;
    transition: all 500ms;
  }

  .fade-exit {
    opacity: 1;
    max-height: 100vh;
  }

  .fade-exit-active {
    opacity: 0;
    max-height: 0;
    padding: 0;
    margin: 0 auto;
    transition: all 1000ms, max-height 500ms;
  }
}

.alerts-container {
  position: relative;
  min-width: 200px;
  max-width: 400px;
  background: $purple;
  color: $white;
  padding: .5rem;
  margin: .5rem auto;
  text-align: center;
  border-radius: 10px;
}

.alerts-container2 {
  background: $clean;
  min-width: 200px;
  max-width: 400px;
  padding: .8rem;
  margin: .5rem auto;
  border: solid 1px #f5f6fa;
  box-shadow: 0 8px 16px 0 #949ba0;
  border-radius: 8px;
}

.alert-line{
  width: 8px;
  background-color: $primary;
  margin-left: -15px;
  margin-top: -15px;
  margin-bottom: -15px;
  border-radius: 8px 0 0 8px;
}

.alert-align-right{
  margin: .5rem 0 .5rem auto;
}

.alert-title{
  font-family: $font-family-raleway;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $secondary-lighten-1;
}

.alert-description{
  color: $secondary-lighten-3;
}


.alert {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  @media (max-width: map-get($grid-breakpoints, "md")) {
    margin-right: math.div($grid-gutter-width, -2);
    margin-left: math.div($grid-gutter-width, -2);
    font-size: 12px;
    border-radius: 0;
  }

  &.alert-enter {
    opacity: 0.1;
    max-height: 0;
    padding: 0;
    margin-bottom: 0;
  }

  &.alert-enter-active {
    opacity: 1;
    max-height: 48px;
    padding: $alert-padding-y $alert-padding-x;
    margin-bottom: $alert-margin-bottom;
    transition: all 500ms ease-in;
  }

  &.alert-exit {
    opacity: 1;
    max-height: 48px;
  }

  &.alert-exit-active {
    opacity: 0;
    padding: 0;
    margin-bottom: 0;
    max-height: 0;
    transition: all 500ms ease-out;
  }
}
