.modal-backdrop{
  background-color: rgba(114, 114, 124, 0.5);
  &.modal-content-full{
    opacity: 1;
  }
  &.over{
    z-index: 1050;
  }
}
.modal{
  .modal-dialog{
    max-width: 570px;
    margin: auto !important;
    padding: 1em;
    &.modal-full{
      max-width: 80%;
      @media (max-width: map-get($grid-breakpoints, "sm")) {
        max-width: 100%;
      }
    }
    .modal-content{
      border: none;
      box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.5);
      border-radius: .75rem;
      line-height: 1.5;
      padding: 1.5rem 1em;
      color: $gray-600;
      @media (max-width: map-get($grid-breakpoints, "sm")) {
        padding: 1rem;
      }
      .modal-header {
        padding: 0 0 .5rem 0;
        border-bottom: none;
        h5{
          font-size: 32px;
          line-height: 1.5;
          color: $gray-800;
          font-weight: normal;
          font-family: $font-family-raleway;
        }
      }
      .modal-body{
        padding: .5rem 0 .5rem 0;
        .pdf-object {
          min-height: 60vh;
          width: 700px;
        }
      }
      .modal-footer{
        padding: 1rem 0 0.5rem;
      }
      &.modal-content-full{
        box-shadow: none !important;
        background: transparent;
        .modal-body{
          padding: 0 1em;
        }
        .close{
          opacity: 1;
        }
      }
    }
  }
}
.modal-open{
  .modal {
    display: flex !important;
    align-items: center;
  }
  .modal-quote-landing {
    display: block !important;
    align-items: center;
  }
}

.img-detail{
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    max-height: 340px;
  }
  object {
    width: 100%;
    height: 300px;
    max-height: 300px;
  }
}

.file-thumbnail{
  .thumbnail{
    justify-content: center;
    .icon{
      width: 32px;
      height: 32px;
      border-radius: 0;
    }
  }
}

.modal-loan-validation{
  .wrapper-item-validation{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
