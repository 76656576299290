.badge-bordered {
  color: #fff;
  background-color: $purple;
  border: 1px solid $white;
  box-shadow: 0 0 0 2px $purple;
  font-family: $font-family-raleway;
  font-size: 23px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;

  &.md {
    width: 46px;
    height: 46px;
  }

  &.disabled {
    background-color: $gray-100;
    color: #a0aaad;
    border: 1px solid #c6c6c6;
    box-shadow: none;
  }
}

.wizard-steps {
  &.badge-item {
    border: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: $font-family-raleway;
    padding: .75rem;
    margin: 0;

    &.disabled {
      color: $gray-500;
    }

    &.checked {
      color: $primary;
      cursor: pointer;
    }

    &.current-checked {
      color: $primary;
      cursor: pointer;
    }
  }
}

.step-group-left {
  margin-left: 64px !important;
}

.steps-list {
  .badge-step-line {
    padding-bottom: 2em;
    position: relative;
    &.checked:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: solid 1px $primary;
      margin-left: 23px;
      z-index: -1;
      transform: translate(-50%);
    }

    &.disabled:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: solid 1px $wrapper-wizard-content;
      margin-left: 23px;
      z-index: -1;
      transform: translate(-50%);
    }

    &.current-checked:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: solid 1px #c6c6c6;
      margin-left: 23px;
      z-index: -1;
      transform: translate(-50%);
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}