//
// Variable Overrides
//
$gray-100: #f7f7fa;
$gray-200: #f3f3f7;
$gray-300: #e6e6ed;
$gray-400: #b3b3bb;
$gray-500: #93939c;
$gray-600: #72727c;
$gray-700: #53535a;
$gray-800: #333337;
$gray-900: #202020;
$blue: #014481;
$blue-200: #1c558a;
$blue-300: #03345f;
$purple: #7859ab;
$purple-200: #8a66c4;
$purple-300: #522691;
$orange: #e56901;
$green: #64b700;
$primary: $purple;
$primary-bbva: $blue;
$secondary: $gray-900;
$info: $purple;
$danger: $orange;
$clean: #fff;
$highlight: #f5267a;
$warning: $orange;
$placeholder: #4e5359;
$principal-simulator: #64ab8c;
$turbo-cash-simulator: #e0252d;
$red: #e0252d;
$wrapper-wizard-content: #f7f8fc;
$budge: #E73951;

$body-color: $gray-600;

$font-family-base: 'Lato', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;
$font-size-title-step: 2rem;
$font-color-title-step: $gray-800;
// $text-muted: $gray-600;

$table-accent-bg: $gray-100;
$table-border-color: #fff;
$table-hover-bg: $gray-100;
$step-section-title-bg: $gray-100;
$step-section-title-color: $gray-800;

$input-padding-x: 0;
$input-border-width: 0;
$input-border-radius: 0;
$input-focus-box-shadow: none;
$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-bg: #fff;
$custom-control-indicator-focus-box-shadow: none;

$border-color: $gray-300;

$custom-checkbox-indicator-border-radius: 25%;

/*shadows*/
$z-depth-0: none !important;
$z-depth-05: 0 1px 2px 0 rgba(0,0,0,0.03),0 1px 5px 0 rgba(0,0,0,0.02);
$z-depth-1: 0 2px 5px 0 rgba(0, 0, 0, 0.03), 0 2px 10px 0 rgba(0, 0, 0, 0.03);
$z-depth-1-half: 0 5px 11px 0 rgba(0, 0, 0, 0.03), 0 4px 15px 0 rgba(0, 0, 0, 0.03);
$z-depth-2: 0 8px 17px 0 rgba(0, 0, 0, 0.03), 0 6px 20px 0 rgba(0, 0, 0, 0.03);
$z-depth-2-top: 0 -4px 17px 0 rgba(0, 0, 0, 0.1), 0 -3px 20px 0 rgba(0, 0, 0, 0.1);
$z-depth-3: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
$z-depth-4: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
$z-depth-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);

/*select search component vars*/
$form-text-label-transform: uppercase;
$form-text-label-size: .75rem;
$form-label-letter-spacing: 2px;
$background-select-search-component: $clean;
$radius-select-search-component: 5px;
$shadow-select-search-component: $z-depth-2;

// $form-feedback-valid-color: $gray-400;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 7)
), $spacers);

$height-global-menu: 72px;
$height-search-menu: 54px;
$height-list-menu: calc(100vh - (#{$height-global-menu} + #{$height-search-menu}));
$width-admin-aside: 366px;
$width-admin-nav: 64px;
$width-admin-main: calc(100% - #{$width-admin-nav});
$width-admin-section: calc(100% - #{$width-admin-aside});
$height-loan-application-detail-container: 79px;
$height-main-menu-container: 47px;
$height-main-menu-secondary-container: 62px;
$height-main-menu-doc-container: 325px;
$height-main-menu-preview-doc-container: 280px;
$height-vertical-menu-container: calc(100vh - (#{$height-loan-application-detail-container} + #{$height-main-menu-container}));
$height-vertical-menu-secondary-container: calc(100vh - (#{$height-loan-application-detail-container} + #{$height-main-menu-container} + #{$height-main-menu-secondary-container}));
$height-admin-title-container: 64px;
$height-admin-search-container: 100px;
$height-admin-container: calc(100vh - (#{$height-admin-title-container} + #{$height-admin-search-container}));
$height-admin-container-visits: calc(100vh - (#{$height-admin-title-container} + #{$height-admin-search-container} * 2));
$transition-cubic-in: all .8s cubic-bezier(0.22, 0.61, 0.36, 1);
$transition-cubic-out: all .8s cubic-bezier(0.22, 0.61, 0.36, 1);
$transition-cubic-out-slow: all 2s cubic-bezier(0, 0.8, 0, 0.97);
$height-form-container: calc(100vh - #{$height-admin-title-container});
$height-form-container-sidebar: calc(100vh - (#{$height-admin-title-container} + #{$height-main-menu-secondary-container}));
$height-form-container-sidebar-doc-container: calc(100vh - #{$height-main-menu-doc-container});
$height-form-container-sidebar-preview-doc-container: calc(100vh - #{$height-main-menu-preview-doc-container});

$grid-breakpoints: () !default;
$grid-breakpoints: map-merge((
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1441px
), $grid-breakpoints);

$container-max-widths: () !default;
$container-max-widths: map-merge((
        sm: 540px,
        md: 720px,
        lg: 1200px,
        //xl: 1140px
), $container-max-widths);


//
// Chats
//
$height-chats-loan-list-header: 188px;
$height-chat-subheader: 42px;
$height-chat-searchbar: 56px;
$height-chat-messages-footer: 70px;
$height-chat-group-list-container: calc(100vh - #{$height-global-menu});
$height-chat-list-container: calc(100vh - #{$height-chats-loan-list-header} - #{$height-chat-searchbar});
$height-chat-list-container-without-header: calc(100vh - #{$height-chats-loan-list-header} + #{$height-chat-searchbar} + 6px);
$height-chat-content: calc(
        100vh - #{$height-loan-application-detail-container} - #{$height-chat-subheader}
);
$height-docs-content: calc(
        100vh - #{$height-loan-application-detail-container}
);
